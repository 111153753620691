import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import FullResourceGrid from '../components/organisms/FullResourceGrid';
import ResourceListHero from '../components/organisms/ResourceListHero';
import ResourcesHeader from '../components/organisms/ResourcesHeader';
import SearchResults from '../components/organisms/SearchResults';
import { useSearch } from '../hooks/use-search';

const ReportsPage = ({ data: { data, allWpGuide, siteSearchIndex } }) => {
  const [query, setQuery] = useState('');
  const results = useSearch(siteSearchIndex.index, query);

  const resourceResults = results
    .filter(({ nodeType }) => nodeType === 'Guide')
    .map(
      ({ slug }) =>
        allWpGuide.edges.find((post) => post.node.slug === slug)?.node,
    );

  return (
    <Layout>
      <Seo post={data} />

      <ResourcesHeader activeSearchQuery={query} onQuery={setQuery} />
      {query.trim().length > 0 ? (
        <SearchResults resources={resourceResults} />
      ) : (
        <>
          <ResourceListHero
            {...allWpGuide.edges.map(({ node }) => node)[0]}
            listTitle="Guides &amp; Reports"
          />
          {allWpGuide.edges.length > 1 && (
            <>
              <div className="border-b border-keyline" />
              <FullResourceGrid
                title="Recent Guides &amp; Reports"
                resources={allWpGuide.edges.slice(1).map(({ node }) => node)}
              />
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query ReportsPageQuery {
    data: wpPage(slug: { eq: "guides" }) {
      title
      ...SEO
    }
    allWpGuide(sort: { fields: dateGmt, order: DESC }) {
      edges {
        node {
          title
          nodeType
          slug
          excerpt
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
    siteSearchIndex {
      index
    }
  }
`;

export default ReportsPage;
